import React from 'react'

function Sponsers() {
  return (
    <div  id="sponsorship">
        
        <h2 style={{
        fontFamily: 'PT sans',
        color: '#65A0FB',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        margin: '20px 0',
        textAlign:"center"
      }}>Sponsorship Opportunities</h2>



        <img src="./images/Sponsers/sponsers.png" style={{height:"90%", width:"90%"}}/>
    </div>
  )
}

export default Sponsers